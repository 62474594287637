<nb-card size="tiny">
  <nb-card-header
    >{{ cardTitle }}
    <h3>{{ quantity() | numbers }}</h3>
  </nb-card-header>
  <nb-card-body>
    <div class="row align-items-center">
      <div class="col">
        <button
          nbButton
          status="success"
          (click)="add()"
          [disabled]="canAfford()"
        >
          Click
        </button>
      </div>
      <div class="col">
        <div class="costs">
          <span class="text-success"
            >+{{ next() | numbers }} {{ itemName }}</span
          >
          <div *ngIf="resource == 'clicks'">
            <span class="text-success"
              >{{ gs.game.clicksPerSecond | numbers }} clicks/s</span
            >
          </div>
          <div *ngIf="cost()">
            <span class="text-danger">-{{ cost() | numbers }} clicks</span>
          </div>
        </div>
      </div>
    </div>
  </nb-card-body>
</nb-card>
