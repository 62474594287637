<td class="buyer d-block d-sm-table-cell">
  <div [class]="relationshipStatus(buyer.random)">
    <nb-user
      size="large"
      [name]="buyer.name.first + ' ' + buyer.name.last"
      [badgeText]="relationship?.value | numbers"
      [title]="'#' + order.id"
      [badgeStatus]="relationship?.value > 0 ? 'success' : 'danger'"
      badgePosition="bottom right"
      [picture]="'./assets/images/people/' + order.person + '.jpg'"
      nbTooltip="Color indicates how good is the buyers base offer, notification badge shows how many deals or rejections you have made with the buyer."
    >
    </nb-user>
  </div>
</td>
<td class="clicks  d-inline-block d-sm-table-cell">
  <strong>{{ order.clicks | numbers }}</strong>
  <span class="d-inline d-sm-none"> clicks</span>
</td>
<td [class]="'offer d-inline-block d-sm-table-cell ' + order.unit">
  <span
    >{{ order.offer | numbers }} <small>{{ order.unit }}</small></span
  >
</td>
<td class="unit d-inline-block d-sm-table-cell">
  <span *ngIf="order.clicks / order.offer <= 1">&lt;1</span
  ><span *ngIf="order.clicks / order.offer > 1">{{
    order.clicks / order.offer | numbers
  }}</span>
  <small> clicks <span class="d-inline d-sm-none">/ unit</span></small>
</td>
<td class="actions text-right  d-block d-sm-table-cell">
  <button
    nbButton
    size="small"
    (click)="hold()"
    [status]="order.held ? 'primary' : 'default'"
  >
    {{ order.held ? "Held" : "Hold" }}
  </button>
  <button nbButton status="danger" size="small" (click)="reject()">
    Reject
  </button>

  <button
    nbButton
    status="success"
    size="small"
    (click)="sell()"
    [disabled]="gs.game.clicks <= order.clicks"
  >
    Sell
  </button>
</td>
