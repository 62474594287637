<div class="row">
  <app-click-item
    class="col-sm-3"
    resource="clicks"
    itemName="clicks"
    cardTitle="Clicks"
  ></app-click-item>
  <app-click-item
    class="col-sm-3"
    resource="value"
    itemName="value"
    cardTitle="Click value"
  ></app-click-item>
  <app-click-item
    class="col-sm-3"
    resource="auto"
    itemName="clicker"
    cardTitle="Auto clickers"
  ></app-click-item>
  <app-click-item
    class="col-sm-3"
    resource="multiplier"
    itemName="multiplier"
    cardTitle="Multiplier"
  ></app-click-item>
</div>
