<nb-card status="warning" size="small">
  <nb-card-header
    >Wallet
    <nb-icon
      icon="question-mark"
      nbTooltip="Wallet is where you store your clicks, visits, views, reads, shares, downloads, money and crypto."
      class="text-right"
    ></nb-icon>
  </nb-card-header>
  <!-- <nb-card-body *ngIf="!this.fb.user">
    <nb-tabset fullWidth>
      <nb-tab tabTitle="Login"> <app-login></app-login></nb-tab>
      <nb-tab tabTitle="Register"> <app-register></app-register></nb-tab>
    </nb-tabset>
  </nb-card-body> -->
  <nb-card-body *ngIf="this.user">
    <!-- <div class="load" *ngIf="!this.user">
      <button nbButton status="primary" (click)="wallet()">
        Load wallet
      </button> -->
    <!-- </div> -->
    <div *ngIf="this.user">
      <div class="row">
        <div class="col-auto col-sm-8">
          <ul class="wallet">
            <li>
              <span>{{ this.user.clicks | numbers }}</span>
              <small> clicks</small>
            </li>
            <li>
              <span>{{ this.user.visits | numbers }}</span>
              <small> visits</small>
            </li>
            <li>
              <span>{{ this.user.views | numbers }}</span> <small> views</small>
            </li>
            <li>
              <span>{{ this.user.reads | numbers }}</span> <small> reads</small>
            </li>
            <li>
              <span>{{ this.user.shares | numbers }}</span>
              <small> shares</small>
            </li>
            <li>
              <span>{{ this.user.downloads | numbers }}</span
              ><small> downloads</small>
            </li>
            <li>
              <span>{{ this.user.money | numbers }}</span
              ><small> money</small>
            </li>
            <li>
              <span>{{ this.user.crypto | numbers }}</span
              ><small> crypto</small>
            </li>
          </ul>
        </div>
        <div class="col col-sm-4">
          <div class="boosts">
            <div
              class="boost"
              nbTooltip="Enable to increase manual click value"
            >
              <button
                nbButton
                [status]="boost_manual ? 'success' : 'basic'"
                [disabled]="this.user.boost_manual ? false : true"
                (click)="toggleBoost('boost_manual', this.user.boost_manual)"
                size="large"
                fullWidth
              >
                <nb-badge
                  [text]="this.user.boost_manual || 0"
                  status="basic"
                  position="bottom right"
                ></nb-badge>
                <nb-icon icon="person-add"></nb-icon>
              </button>
            </div>
            <div
              class="boost"
              nbTooltip="Enable to increase analytics generation"
            >
              <button
                nbButton
                [status]="boost_analytics ? 'success' : 'basic'"
                [disabled]="this.user.boost_analytics ? false : true"
                (click)="
                  toggleBoost('boost_analytics', this.user.boost_analytics)
                "
                size="large"
                fullWidth
              >
                <nb-badge
                  [text]="this.user.boost_analytics || 0"
                  status="basic"
                  position="bottom right"
                ></nb-badge>
                <nb-icon icon="bar-chart-2"></nb-icon>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="actions">
        <button
          nbButton
          [status]="reloading ? 'success' : 'warning'"
          class="reload"
          (click)="reload()"
          [disabled]="loading"
          nbTooltip="This will start a new run - it's a way to advance in the game"
        >
          <span>New run</span>
          <nb-icon [icon]="reloading ? 'checkmark' : 'skip-forward'"></nb-icon>
        </button>

        <button
          nbButton
          status="primary"
          (click)="withdraw()"
          [disabled]="reloading || loading"
        >
          <span>Withdraw</span>
          <nb-icon icon="arrow-up"></nb-icon>
        </button>
        <button
          nbButton
          class="ml-2"
          status="primary"
          (click)="deposit()"
          [disabled]="reloading || loading"
        >
          <span>Deposit</span>
          <nb-icon icon="arrow-down"></nb-icon>
        </button>

        <!-- <nb-action
          icon="log-out"
          status="primary"
          (click)="logout()"
          nbTooltip="Log out"
          class="ml-2 logout"
        >
        </nb-action> -->
      </div>
      <div class="deposit-warning text-danger text-right">
        <small>Remember to deposit before exiting the game!</small>
      </div>
    </div>
  </nb-card-body>
</nb-card>
