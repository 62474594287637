<nb-card status="primary" size="medium">
  <nb-card-header
    >Analytics<nb-icon
      icon="question-mark"
      nbTooltip="Buy generators to increase analytics production and unlock new tasks."
    ></nb-icon
  ></nb-card-header>
  <nb-list>
    <nb-list-item>
      <app-analytics-item
        [measurement]="analytics.visits"
        analytic="visits"
      ></app-analytics-item>
    </nb-list-item>

    <nb-list-item>
      <app-analytics-item
        [measurement]="analytics.views"
        analytic="views"
      ></app-analytics-item>
    </nb-list-item>

    <nb-list-item>
      <app-analytics-item
        [measurement]="analytics.reads"
        analytic="reads"
      ></app-analytics-item>
    </nb-list-item>

    <nb-list-item>
      <app-analytics-item
        [measurement]="analytics.shares"
        analytic="shares"
      ></app-analytics-item>
    </nb-list-item>

    <nb-list-item>
      <app-analytics-item
        [measurement]="analytics.downloads"
        analytic="downloads"
      ></app-analytics-item>
    </nb-list-item>

    <nb-list-item>
      Total generators: {{ this.as.totalGenerators() }}
    </nb-list-item>
  </nb-list>
</nb-card>
