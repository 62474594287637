<nb-card status="primary" size="tiny">
  <nb-card-header>Orders</nb-card-header>
  <nb-card-body class="overflow-hidden">
    <div class="row">
      <div class="col-sm-3">
        <nb-select
          placeholder="Buy"
          filled
          status="primary"
          size="small"
          fullWidth
        >
          <nb-option value="buy">Buy</nb-option>
          <nb-option value="sell">Sell</nb-option>
        </nb-select>
      </div>
      <div class="col-sm-9">
        <input
          nbInput
          [formControl]="quantity"
          placeholder="Quantity"
          type="text"
          pattern="\d*"
          maxlength="42"
          inputmode="numeric"
          fieldSize="small"
          [nbTooltip]="quantity.value | numbers"
          fullWidth
        />
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div class="row align-items-center">
      <div class="col-sm-5">
        <nb-select placeholder="Metric" size="small" fullWidth>
          <nb-option value="clicks">Clicks</nb-option>
          <nb-option value="visits">Visits</nb-option>
          <nb-option value="views">Views</nb-option>
          <nb-option value="reads">Reads</nb-option>
          <nb-option value="shares">Shares</nb-option>
          <nb-option value="downloads">Downloads</nb-option>
        </nb-select>
      </div>
      <div class="col-sm-4">
        <div class="price" nbTooltip="{{ quantity.value | numbers }}">
          {{ quantity.value * 0.001 | currency }}
        </div>
      </div>
      <div class="col-sm-3 text-right">
        <button nbButton status="primary" size="small">Place</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
