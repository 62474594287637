<nb-card [size]="collapsed ? 'tiny' : 'large'" accent="success">
  <nb-card-header
    >Market
    <nb-icon
      icon="question-mark"
      nbTooltip="Sell your clicks for analytics units. Orders marked in green tend to be better than average."
    ></nb-icon>
    <nb-icon
      icon="collapse"
      (click)="collapse()"
      nbTooltip="Resize market"
      class="cursor-pointer"
    ></nb-icon>
    <button
      nbButton
      status="info"
      size="small"
      (click)="generateOrder()"
      [disabled]="canGenerateOrder()"
    >
      Refresh
    </button>
  </nb-card-header>
  <nb-card-body>
    <table class="market table">
      <thead *ngIf="!collapsed">
        <tr>
          <th>Buyer</th>
          <th>Clicks</th>
          <th>Offer</th>
          <th>Unit cost</th>
          <th class="text-right">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          app-market-item
          *ngFor="let order of market.orders"
          [class]="'market-entry d-block d-sm-table-row ' + order.special"
          [order]="order"
          [@inAnimation]
        ></tr>
      </tbody>
    </table>
  </nb-card-body>
  <!-- <nb-card-footer>By Wikipedia</nb-card-footer> -->
</nb-card>
