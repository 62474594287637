<nb-card status="success" size="large">
  <nb-card-header class="projects-header"
    >Projects
    <nb-icon
      icon="question-mark"
      nbTooltip="Completing a project will grant you assets. Projects marked in green reset analytics generator costs and increase production."
    ></nb-icon>
    <button
      nbButton
      status="warning"
      size="small"
      (click)="generateProject()"
      [disabled]="canGenerate()"
    >
      Generate
    </button></nb-card-header
  >
  <nb-list>
    <nb-list-item
      *ngFor="let project of this.ps.availableProjects()"
      [class]="this.specialProject(project.id) ? 'special' : ''"
      [@inOutAnimation]
    >
      <div class="project-item row no-gutters">
        <div class="col-auto col-sm-1">
          <div><small>#</small>{{ project.id }}</div>
        </div>
        <div class="col-12 col-sm-7">
          <div class="requirements">
            <span class="visits">
              <span>{{ project.visits | numbers }}</span>
            </span>
            <span class="views">
              <span>{{ project.views | numbers }}</span>
            </span>
            <span class="reads">
              <span>{{ project.reads | numbers }}</span>
            </span>
            <span class="shares">
              <span>{{ project.shares | numbers }}</span>
            </span>
            <span class="downloads">
              <span>{{ project.downloads | numbers }}</span>
            </span>
          </div>
        </div>
        <div class="col-6 col-sm-3">
          <h5 class="money">
            $ {{ project.money | numbers }}
            <span *ngIf="project.crypto">
              ⛓ {{ project.crypto | numbers }}</span
            >
          </h5>
        </div>
        <div
          class="col-12 col-sm-1 text-right"
          [nbPopover]="popover"
          nbPopoverTrigger="hint"
          nbPopoverPlacement="bottom"
        >
          <button
            nbButton
            status="success"
            (click)="complete(project)"
            [disabled]="canComplete(project)"
          >
            <nb-icon icon="checkmark"></nb-icon>
          </button>
        </div>
      </div>
      <ng-template #popover>
        <div class="projects-popover medium-popover">
          <div class="row">
            <div class="col-sm-12">
              <ul class="costs">
                <li
                  [class]="
                    this.as.analytics.visits.amount >= project.visits
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ project.visits | numbers }} <small>visits</small>
                </li>
                <li
                  [class]="
                    this.as.analytics.views.amount >= project.views
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ project.views | numbers }} <small>views</small>
                </li>
                <li
                  [class]="
                    this.as.analytics.reads.amount >= project.reads
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ project.reads | numbers }} <small>reads</small>
                </li>
                <li
                  [class]="
                    this.as.analytics.shares.amount >= project.shares
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ project.shares | numbers }} <small>shares</small>
                </li>
                <li
                  [class]="
                    this.as.analytics.downloads.amount >= project.downloads
                      ? 'text-success'
                      : 'text-danger'
                  "
                >
                  {{ project.downloads | numbers }} <small>downloads</small>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </ng-template>
    </nb-list-item>
  </nb-list>
</nb-card>
