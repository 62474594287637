<nb-card status="info" size="medium">
  <nb-card-header
    >Tasks
    <nb-toggle
      status="primary"
      [(ngModel)]="showAvailable"
      nbTooltip="Show only available tasks"
    ></nb-toggle
  ></nb-card-header>
  <nb-list [class]="'filter-' + showAvailable">
    <nb-list-item *ngFor="let item of availableTasks()" [@inOutAnimation]>
      <nb-checkbox
        [checked]="item.completed"
        [disabled]="available(item)"
        [nbPopover]="popover"
        nbPopoverTrigger="hint"
        nbPopoverPlacement="bottom"
        (checkedChange)="complete(item)"
        [status]="item.status"
        >{{ item.title }}</nb-checkbox
      >
      <ng-template #popover>
        <div class="custom-popover">
          <span>{{ item.description }}</span>
        </div>
      </ng-template>
    </nb-list-item>
  </nb-list>
  <nb-card-footer *ngIf="availableTasks().length == 0"
    ><h5>Dashclicker</h5>
    <p class="subtitle">
      Thank you for playing!
    </p>
    <p>
      Follow
      <a href="https://reddit.com/r/dashclicker" target="_blank"
        >r/Dashclicker</a
      >
      for the latest updates. <br />
      You can also
      <a href="https://github.com/armn/dashclicker" target="_blank"
        >view the source code on GitHub.</a
      >
    </p>
  </nb-card-footer>
</nb-card>
